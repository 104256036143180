import './App.css';

import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import ImportModal from './pages/slots/components/ImportModal';
import SlotErfassenModal from './pages/slots/components/SlotErfassenModal';
import Login from './pages/login/Login';


import Navigation from './containers/navigation/index';


import PasswordReset from './pages/password-reset/PasswordReset';

import { Modal } from './containers/modal/modal';

import AddUserModal from './pages/hubs/containers/AddUserModal';
import { TFAModal } from './containers/two-factor-authentication';

import Anmeldungen from './pages/anmeldungen/Anmeldungen';
import LeiterSignup from './pages/leiter-signup/LeiterSignup';


import SingleAnmeldung from './pages/anmeldung-single/SingleAnmeldung';

import i18n from './translation/i18n';

import {
  SingleSlotPage, SlotsOverviewPage,
  Leiterpersonen,
  NeueLeiterperson,
  Hubs, SingleHub,
  NewUser,
  User,
  Regionen,
  NewRegion,
  Newsletter,
  Dashboard,
  Postleitzahlen
} from './pages';

import Einstellungen from './pages/einstellungen/Einstellungen';

import ErrorDisplay from './context/ErrorDisplay';
import EnforceTfa from './pages/enforce-tfa/EnforceTfa';


function App() {
  const location = useLocation();
  const background = location.state?.background;

  const navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path="/enforce-tfa" element={<EnforceTfa />} />

        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/invitation" element={<PasswordReset invitation={true} />} />
        <Route path="/" element={<Login />} />
        <Route path="/leiterpersonen" element={<LeiterSignup />} />


        <Route path="slots" element={<SlotsOverviewPage />} />


        <Route path="/app" element={<Navigation />}>
          <Route path="/app/leiterpersonen" element={<Leiterpersonen />} />
          <Route path="/app/leiterpersonen/neu" element={<NeueLeiterperson />} />
          <Route path="/app/leiterpersonen/bearbeiten/:id" element={<NeueLeiterperson isEditPage={true} />} />
          <Route path="/app/plz" element={<Postleitzahlen />} />


          <Route path="slots/neu" element={<SingleSlotPage />} >
            <Route path="importieren" element={<Modal onClose={() => navigate('/app')} />} />
            <Route path="erfassen" element={<Modal />} />
          </Route>

          <Route path="slots" element={<SlotsOverviewPage />} />

          <Route path="/app/slots/bearbeiten/:id" element={<SingleSlotPage isEditPage={true} />} />

          <Route path="/app/anmeldungen/bearbeiten/:id" element={<SingleAnmeldung isEditPage={true} />} />



          <Route path="/app/anmeldungen" element={<Anmeldungen />} />


          <Route path="/app/hubs" element={<Hubs />} />
          <Route path="/app/hubs/neu" element={<SingleHub />} />
          <Route path="/app/hubs/bearbeiten/:id" element={<SingleHub isEditPage={true} />} >
            <Route path="/app/hubs/bearbeiten/:id/user" element={<Modal />} />
            <Route path="/app/hubs/bearbeiten/:id/user/:userId" element={<Modal />} />
          </Route>

          <Route path="/app/user/neu" element={<NewUser />} />
          <Route path="/app/user/bearbeiten/:userId" element={<NewUser isEditPage={true} />} />
          <Route path="/app/user" element={<User />} />
          <Route path="/app/newsletter" element={<Newsletter />} />

          <Route path="/app/regionen" element={<Regionen />} />
          <Route path="/app/regionen/bearbeiten/:id" element={<NewRegion isEditPage={true} />} />

          <Route path="/app/regionen/neu" element={<NewRegion />} />


          <Route path="/app/einstellungen" element={<Einstellungen />} >
            <Route path="/app/einstellungen/tfa" element={<Modal />} />
          </Route>

          <Route path="/app/dashboard" element={<Dashboard />} />
          <Route path="/app" element={<Dashboard />} />
          <Route path="*" element={<Dashboard />} />

        </Route>

      </Routes>

      {
        background ? (
          <Routes>
            <Route path="/app/slots/neu/importieren" element={<ImportModal onClose={() => navigate(-1)} />} />
            <Route path="/app/slots/neu/erfassen" element={<SlotErfassenModal onClose={() => navigate(-1)} />} />

            <Route path="/app/hubs/bearbeiten/:id/user" element={<AddUserModal />} />
            <Route path="/app/hubs/bearbeiten/:id/user/:userId" element={<TFAModal />} />
            <Route path="/app/einstellungen/tfa" element={<TFAModal />} />

          </Routes>
        ) : null
      }
      <Outlet />
      <ErrorDisplay />
    </div >
  );
}

export default App;
