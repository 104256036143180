import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import de from './de/translation.json';
import fr from './fr/translation.json';
import it from './it/translation.json';

import navigationDe from './de/navigation.json';
import navigationFr from './fr/navigation.json';
import navigationIt from './it/navigation.json';

import dashboardDe from './de/dashboard.json';
import dashboardFr from './fr/dashboard.json';
import dashboardIt from './it/dashboard.json';

import loginDe from './de/login.json';
import loginFr from './fr/login.json';
import loginIt from './it/login.json';

import anmeldungDe from './de/anmeldung.json';
import anmeldungFr from './fr/anmeldung.json';
import anmeldungIt from './it/anmeldung.json';

import einstellungenDe from './de/einstellungen.json';
import einstellungenFr from './fr/einstellungen.json';
import einstellungenIt from './it/einstellungen.json';


const localStorageLanguage = localStorage.getItem('language')?.split('-')[0]



i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                translation: de,
                navigation: navigationDe,
                dashboard: dashboardDe,
                login: {},
                anmeldung: anmeldungDe,
                einstellungen: einstellungenDe,

            },
            fr: {
                translation: fr,
                navigation: navigationFr,
                dashboard: dashboardFr,
                login: loginFr,
                anmeldung: anmeldungFr,
                einstellungen: einstellungenFr,

            },
            it: {
                translation: it,
                navigation: navigationIt,
                dashboard: dashboardIt,
                login: loginIt,
                anmeldung: anmeldungIt,
                einstellungen: einstellungenIt,

            },
        },
        lng: localStorageLanguage || 'de',
        fallbackLng: 'de', // Default language
        debug: false, // Enable debugging in development
    });


export default i18n;
