import { useDirectus } from 'react-directus';
import { useEffect, useState } from 'react';

import { BasicTable } from '@containers/table/BasicTable';
import ContentWrapper from '@containers/content-wrapper/ContentWrapper';

import { getDataOnLoad } from '@helpers/general-helpers';
import { LeiterpersonenEntries } from '../helpers';
import { useTranslation } from 'react-i18next';

const collectionName = 'leiterpersonen';
const fields = null;

const Leiterpersonen = () => {
    const { directus } = useDirectus();
    const [collection, setCollection] = useState(null);
    const { t } = useTranslation('leiterpersonen');

    async function countIds(data) {
        const idCounts = {};
        const promises = [];

        data.forEach(item => {
            const leiterpersonen = item.leiterpersonen;
            console.log(item)

            if (typeof leiterpersonen === 'string' && leiterpersonen.startsWith('[')) {
                const ids = JSON.parse(leiterpersonen);

                ids.forEach(id => {

                    promises.push(getWorkshopsPerSlot(item.id).then(counter => {
                        console.log(`Counter for ID ${item.id}: ${counter}`);
                        idCounts[id] = (idCounts[id] || 0) + counter;
                    }));
                });
            }
        });

        await Promise.all(promises);
        return idCounts;
    }


    const getWorkshopsPerSlot = async (id) => {
        return directus.items('workshops').readByQuery({ filter: { slot: { _in: [id] } } }).then(data => {
            return data.data.length;
        });
    }


    const getWorkshopsPerLeiterperson = () => {
        return directus.items('slots')
            .readByQuery({ fields: 'leiterpersonen,id' })
            .then(data => {
                return countIds(data.data);
            });
    }



    useEffect(() => {

        getWorkshopsPerLeiterperson().then((idCounts) => {

            getDataOnLoad(collectionName, fields, directus)
                .then((items) => {
                    items.data.map(item => {
                        item.counter = idCounts[item.id] || 0;
                        return item;
                    }
                    )

                    const leiterpersonenItems = new LeiterpersonenEntries(items.data);

                    setCollection(leiterpersonenItems);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });

        }


        );


    }, []);

    return <ContentWrapper title={t('Leiterpersonen')} >
        {collection ?
            <BasicTable entries={collection} filter={['vorname', 'nachname', 'email', 'erste_ausbildung', 'counter']} />
            : null}
    </ContentWrapper>;
};

export default Leiterpersonen;