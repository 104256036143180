import { Outlet, useNavigate, Link } from "react-router-dom";
import './navigation.scss';
import NavItem from "./NavItem";
import { useState, useEffect } from "react";
import { useDirectus } from 'react-directus';
import { useTranslation } from 'react-i18next';

const Navigation = () => {
    const { t, i18n } = useTranslation('navigation');
    const navigate = useNavigate();
    const [me, setMe] = useState(null);
    const [navItems, setNavItems] = useState([]);
    const { directus } = useDirectus();

    const navigationConfig = {
        admin: [
            { name: t('Dashboard'), link: '/app/dashboard' },
            { name: t('Anmeldungen'), link: '/app/anmeldungen' },
            { name: t('Leiterpersonen'), link: '/app/leiterpersonen' },
            { name: t('Newsletter'), link: '/app/newsletter' }
        ],
        user: [
            { name: t('Dashboard'), link: '/app/dashboard' },
            { name: t('Anmeldungen'), link: '/app/anmeldungen' },
            { name: t('Slots'), link: '/app/slots' },

            { name: t('Leiterpersonen'), link: '/app/leiterpersonen' }
        ],
        settings: [
            { name: t('Einstellungen'), link: '/app/einstellungen' }
        ]
    };

    const getUser = async () => {
        if (!localStorage.auth_token) {
            //navigate('/login');
            return;
        }

        try {
            const newMe = await directus.users.me.read({ fields: ['*', 'role.*'] });
            setMe(newMe);
            const isAdmin = ['958ddffd-55d3-4de2-afd6-6aed8b4dfb2e', 'f31f1ca4-0df8-4842-b3e8-a8fdf7f821dc'].includes(newMe.role.id);
            setNavItems(isAdmin ? navigationConfig.admin : navigationConfig.user);
            console.log(newMe.language)
            localStorage.setItem('language', newMe.language || 'de');
            //i18n.changeLanguage(newMe.language?.split('-')[0]);
        } catch (error) {
            //navigate('/login');
        }
    };


    useEffect(() => {
        getUser();
    }, [i18n.language]);

    const refresh = () => {
        directus.auth.refresh().then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {

        const expires_at = localStorage.getItem('auth_expires_at')

        const timestamp = parseInt(expires_at, 10);
        const now = new Date().getTime();

        if (timestamp < now) {
            refresh()

        }

    }, [])

    return (
        <div className='page-wrapper'>
            <div className='page-wrapper--sidebar'>
                <div>

                    <div className="logo-wrapper">
                        <Link to="https://swiss-cycling.ch" target="_blank" rel="noreferrer noopener">
                            <img src="/assets/Logo_SwissCycling-und-bikecontrol-fb-RGB.jpg" alt="logo" />
                        </Link>
                    </div>
                    <nav>
                        {me && <ul className='main-nav'>
                            {navItems.map(item => <NavItem key={item.name} name={item.name} permalink={item.link} />)}
                        </ul>}
                        <ul className='sub-nav'>
                            {navigationConfig.settings.map(item => <NavItem key={item.name} name={item.name} permalink={item.link} />)}
                        </ul>
                    </nav>
                </div>

                <div className='sidebar-bottom'>
                    <div className="user-teaser">
                        <span className="user-teaser__short">{me ? me.first_name.charAt(0) : ''}</span><span onClick={() => navigate('/login')} className="user-teaser__name">{t('abmelden')}</span>
                    </div>
                </div>

            </div>

            <div className='page-wrapper--main'>

                <Outlet />
                <Link to="/version" className="page-wrapper--version">Version {process.env.REACT_APP_VERSION || '0.9.1'}</Link>
            </div>
        </div>
    );
};

export default Navigation;
