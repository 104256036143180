const getWorkshopById = (id, workshopReports) => {

    const workshop = workshopReports.find((workshop) => workshop.workshopId === id);
    return workshop;
}

const getLeiterpersonName = (id, leiterpersonen) => {
    const leiterperson = leiterpersonen.find((leiterperson) => leiterperson.id === id);
    return leiterperson?.vorname + ' ' + leiterperson?.nachname;
}

const formatDate = (date) => {
    const d = new Date(date);
    const options = {
        weekday: 'short', // "long", "short", or "narrow"
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    console.log(date)
    console.log(d)
    return d.toLocaleDateString('de-DE', options).toString();
};

const getReportEntries = (reports, day) => {
    let entries = [];
    reports.forEach((report) => {

        if (report.days[day] !== undefined) {
            entries.push({ report: report.days[day], leiterpersonId: report.leiterpersonId });
        }
    })

    return entries;

}


const getSingleDays = (slots) => {
    let singleDays = [];

    slots.forEach((slot) => {
        if (!singleDays.includes(slot.date)) {
            singleDays.push(slot.date);
        }
    })

    return singleDays;
}
const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

const checkIfAllDaysAreReported = (slots, reports) => {
    if (slots.length === 0 || isEmpty(slots)) return false;
    let dict = {};
    slots.forEach((slot) => {
        const tmpLeiterpersonen = JSON.parse(slot?.leiterpersonen);
        tmpLeiterpersonen?.forEach((leiterperson) => {
            if (dict[leiterperson] === undefined) {
                dict[leiterperson] = [slot.date];
            } else {
                dict[leiterperson].push(slot.date);
            }
        })
    })

    let allDaysReported = true;
    let notReported = {};

    Object.keys(dict).forEach((key) => {
        const leiterperson = reports.find((report) => report?.leiterpersonId === parseInt(key));
        if (leiterperson === undefined) {
            allDaysReported = false;
            if (notReported[key] === undefined) {
                notReported[key] = [...dict[key]];

            } else {
                notReported[key].push(...dict[key]);
            }
        } else {
            dict[key].forEach((day) => {
                if (leiterperson.days[day] === undefined) {
                    allDaysReported = false;
                    if (notReported[key] === undefined) {
                        notReported[key] = [...dict[key]];

                    } else {
                        notReported[key].push(...dict[key]);
                    }
                }
            })
        }

    })

    return { allDaysReported: allDaysReported, notReported: notReported };
}


const checkIfAllWorkshopsAreReported = (data, workshopReports) => {
    let allWorkshopsReported = true;
    data.workshops.forEach((workshop) => {
        const workshopReport = getWorkshopById(workshop.id, workshopReports);
        if (workshopReport === undefined) {
            allWorkshopsReported = false;
        }
    })
    return allWorkshopsReported;
}

export { getWorkshopById, getLeiterpersonName, formatDate, getReportEntries, getSingleDays, checkIfAllDaysAreReported, checkIfAllWorkshopsAreReported };