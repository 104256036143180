import React, { useEffect, useState } from 'react'
import { formatDate, capitalize } from '@helpers';
import PropTypes from 'prop-types'

export default function SlotItem(props) {
    const { slot, uuid, id, hideButtons } = props;
    const [isAvailable, setIsAvailable] = useState(null);

    function availabilityRequest(uuid, slotId, isAvailable) {
        return new Promise((resolve, reject) => {
            const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

            // Assuming you have the 'uuid' value stored in a variable named 'uuidValue'
            const apiUrl = API_ENDPOINT + '/custom-endpoint'; // Change this to your actual API endpoint


            const url = apiUrl + '/leiterpersonen-availability';
            const data = { uuid, slotId, isAvailable };
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    resolve(data); // Resolve the promise with the response data
                })
                .catch(error => {
                    reject(error); // Reject the promise with the error
                });
        });
    }

    const setAvailability = (available) => {
        // make request to api
        availabilityRequest(uuid, slot.id, available)
            .then(responseData => {
                console.log(responseData)
                checkAvailability(responseData[0]);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const checkAvailability = (tmpSlot) => {
        if (tmpSlot.availability === null || tmpSlot.availability === undefined) {
            setIsAvailable(null);
            return;
        }

        try {
            const obj = JSON.parse(tmpSlot.availability);
            console.log(obj)

            if (obj?.available && obj.available.indexOf(parseInt(id)) !== -1) {
                setIsAvailable(true);
            } else if (obj?.unavailable && obj.unavailable.indexOf(parseInt(id)) !== -1) {
                setIsAvailable(false);
            } else {
                setIsAvailable(null);

            }
        } catch (error) {
            setIsAvailable(null);
            console.error('Error parsing JSON:', error);
        }
    }

    useEffect(() => {
        checkAvailability(slot);
    }, []);

    const onAvailabilityChange = (state) => {
        if (state === isAvailable) {
            setAvailability(null);
        } else {
            setAvailability(state);
        }
    }
    const handleKeyPress = (event, newState) => {
        if (event.key === 'Enter') {
            onAvailabilityChange(newState);
        }
    };

    return (
        <div className="kalender__workshop-item">
            <div>
                <div className="date">{formatDate(slot.date)}</div>
                <div className="zeit">{capitalize(slot.zeit)}</div>
            </div>
            {isAvailable}
            {hideButtons ? null :
                <div>
                    <div
                        className={`button ${isAvailable === false ? 'shady' : ''}`}
                        onClick={() => onAvailabilityChange(true)}
                        onKeyDown={(e) => handleKeyPress(e, true)}
                        tabIndex={0} // Make it focusable
                    >
                        YES
                    </div>
                    <div
                        className={`button not ${isAvailable === true ? 'shady' : ''}`}
                        onClick={() => onAvailabilityChange(false)}
                        onKeyDown={(e) => handleKeyPress(e, false)}
                        tabIndex={0} // Make it focusable
                    >
                        NO
                    </div>
                </div>
            }
        </div>
    );

}

SlotItem.propTypes = {
    slot: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
}