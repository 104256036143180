import React from 'react'

import './workshop-item.scss';
import PropTypes from 'prop-types'

export default function WorkshopItem({ workshop, index, children, onClick }) {

    const toHoursAndMinutes = (timeStr) => {
        if (timeStr === '15:30:00') return '15:15';
        if (timeStr === '10:30:00') return '10:15';

        const [hours, minutes] = timeStr.split(':');
        return hours + ':' + minutes;
    }

    const getDateTimeFromSlot = (slot) => {
        try {


            const dateArray = slot.date.split('-');

            const day = dateArray[2];
            const month = dateArray[1];
            const year = dateArray[0];
            if (workshop.startZeit === null)
                return day + '.' + month + '.' + year;
            else {
                return day + '.' + month + '.' + year + ' ' + toHoursAndMinutes(workshop.startZeit);
            }

        } catch (error) {
            return "Kein Datum";
        }
    }



    return (
        <div key={'workshop-' + workshop?.id} className="workshop-preview" onClick={onClick}>
            <div className="workshop-preview__title">Workshop {index + 1}: {workshop?.bezeichnung}</div>
            <div>
                <div>Lehrperson: {workshop?.lehrperson}</div>
                <div>Email: {workshop?.email_lehrperson}</div>
                <div>Anzahl Teilnehmende: {workshop?.anzahl_schueler}</div>
                <div>Stufe: {workshop?.stufe}</div>

                {workshop.inklusion ? <div><p>An diesem Workshop nehmen Kinder mit einer Beeinträchtigung teil.</p><p>
                    {workshop?.inklusion_info}</p></div> : null}
            </div>
            {

                <div className={"workshop-preview__zeit"}>{getDateTimeFromSlot(workshop.slot)}</div>

            }
        </div >
    );
}

WorkshopItem.propTypes = {
    workshop: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired
}