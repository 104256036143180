class Entries {
    constructor(rawData) {
        // This is the constructor method
        this.rawData = rawData;

        this.flattenedData = rawData.map((obj) => {
            const { name, fields, ...rest } = obj;
            let parsedName = {};
            let parsedFields = {};

            if (name) {
                parsedName = JSON.parse(name);
            }

            if (fields) {
                parsedFields = JSON.parse(fields);
            }

            return {
                ...parsedFields,
                ...rest,
                ...parsedName,
            };
        });

        this.attributes = [];
    }

    getRawData() {
        return this.rawData;
    }

    getData() {
        return this.flattenedData;
    }

    getKeys() {
        return this.keys;
    }

    getFields() {
        return this.attributes;
    }

    getTableColumns(filter) {
        if (filter === undefined) {
            return this.attributes;
        }

        return this.attributes.filter((item) => {
            return filter.includes(item.key);
        });
    }

    // Static method (accessible without instantiating the class)
    static staticMethod() {
        console.log('This is a static method');
    }
}


class Entry {
    constructor(fields) {
        this.fields = fields;

        this.initialState = this.fields.reduce((result, item) => {
            result[item.key] = '';
            return result;
        }, {});

        this.attributes = this.fields.map(item => ({
            selector: row => row[item.key],
            name: item.name,
            sortable: true,
            key: item.key
        }));
    }
    getInitialState() {
        return this.initialState;
    }

    getFields() {
        return this.fields;
    }

    getAttributes() {
        return this.attributes;
    }

}



const UserObject = new Entry([
    { key: 'first_name', name: 'Vorname' },
    { key: 'last_name', name: 'Nachname' },
]);




const AnmeldungObject = new Entry([
    { key: 'schule', name: 'Schule' },
    { key: 'ort', name: 'Ort' },
    { key: 'date', name: 'Datum' },
    { key: 'status', name: 'Status' },
    { key: 'zuteilung', name: 'Zuteilung' }
]);



const RegionenObject = new Entry([
    { key: 'name', name: 'Name' },
    { key: 'hub', name: 'Hub' }
]);



export { Entries, Entry, UserObject, AnmeldungObject, RegionenObject };


