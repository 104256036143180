import React, { useEffect, useState } from 'react'
import { useDirectus } from 'react-directus';
import './reports.scss';
import { useError } from '@context/ErrorContext';

import { getWorkshopById, getLeiterpersonName, formatDate, getReportEntries, checkIfAllDaysAreReported, getSingleDays } from './reports-helper';
import ReiseTable from './ReiseTable';
import OffeneReports from './OffeneReports';
import WorkshopsTable from './WorkshopsTable';

import { useTranslation } from 'react-i18next';

export default function Reports(props) {
    const { data } = props;
    const { directus } = useDirectus();
    const { setErrorMessage, setSuccessMessage } = useError();

    const [singleDays, setSingleDays] = useState([]);
    const [reports, setReports] = useState([]);
    const [workshopReports, setWorkshopReports] = useState([]);
    const [leiterpersonen, setLeiterpersonen] = useState([]);
    const [slots, setSlots] = useState([]);

    const { t } = useTranslation('anmeldung');

    const loadData = (slots) => {
        directus.items('slots').readByQuery({ filter: { id: { _in: slots } }, fields: ['*'] }).then(
            (response) => {

                setSlots(response.data);

                const tmpSingleDays = getSingleDays(response.data);

                tmpSingleDays.sort((a, b) => {
                    return new Date(a) - new Date(b);
                })

                setSingleDays(tmpSingleDays);


            }).catch((e) => {
            })
    }

    const loadReports = (anmeldungId) => {
        directus.items('reports').readByQuery({ filter: { anmeldungId: { _eq: anmeldungId } } }).then(
            (response) => {
                const tmpData = response.data.map((report) => {
                    const fields = JSON.parse(report.fields);
                    return { leiterpersonId: report.leiterpersonId, ...fields }
                })


                setReports(tmpData);

            }).catch((e) => {
            })
    }

    const loadWorkshopReports = (workshopIds) => {
        directus.items('reports').readByQuery({ filter: { workshopId: { _in: workshopIds } } }).then(
            (response) => {
                const tmpData = response.data.map((report) => {
                    const fields = JSON.parse(report.fields);
                    return { workshopId: report.workshopId, ...fields }
                })


                setWorkshopReports(tmpData);

            }).catch((e) => {
            })
    }

    const loadLeiterpersonen = () => {
        directus.items('leiterpersonen').readByQuery().then(
            (response) => {
                const tmpData = response.data.map((item) => {
                    const fields = JSON.parse(item.fields);
                    return { id: item.id, ...fields }
                })


                setLeiterpersonen(tmpData);

            }).catch((e) => {
            })
    }


    const setAnmeldungToCompleted = () => {

        directus.items('anmeldungen').updateOne(data.id, {
            status: 'completed',
            // history: JSON.stringify([{ date: new Date(), message: 'Abgeschlossen' }])
        }).then((response) => {
            setSuccessMessage(t('Workshops an dieser Schule erfolgreich abgeschlossen.'));
            loadData();

        }).catch((e) => {
            setErrorMessage(t('Etwas ist schief gelaufen...'));
        })
    }




    useEffect(() => {
        loadData(data.slots);
        loadReports(data.id);
        loadLeiterpersonen(data.hub.id);
        const workshopIds = data.workshops.map((workshop) => workshop.id);
        loadWorkshopReports(workshopIds);

    }, [data.slots])


    const buttonStyle = (status) => {
        if (status === 'confirmed') {
            return {};
        }
        if (status === 'completed') {
            return { opacity: 0.5, cursor: 'not-allowed' };
        }
        return {};
    }

    return (
        <div>

            <ReiseTable singleDays={singleDays} reports={reports} leiterpersonen={leiterpersonen} slots={slots} />

            <OffeneReports slots={slots} reports={reports} leiterpersonen={leiterpersonen} />

            <WorkshopsTable data={data} workshopReports={workshopReports} />

            <button className="button" style={{
                opacity: data?.status === 'completed' ? 0.5 : undefined,
                cursor: data?.status === 'completed' ? 'not-allowed' : undefined
            }} disabled={data?.status === 'completed' ? true : false} onClick={() => setAnmeldungToCompleted()}>{t('Workshops an dieser Schule abschliessen')}</button >

        </div >
    )
}
