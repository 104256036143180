import React, { Fragment, useEffect, useState } from 'react'
import SlotItem from './SlotItem';
import WorkshopItem from './WorkshopItem';

import PropTypes from 'prop-types'
export default function AnmeldungItem(props) {
    const { anmeldung, uuid, id, dataLoader, hideButtons } = props;
    const [ws, setWs] = useState([])

    const getSlot = (slotId) => {
        return anmeldung.slots.find((slot) => slot.id === slotId);
    }

    const getWorkshops = (workshops) => {
        const tmpWorkshops = workshops.map((workshop) => {
            return {
                ...workshop,
                slot: getSlot(workshop.slot)
            }
        })

        console.log(tmpWorkshops)
        tmpWorkshops.sort(customSort);

        setWs(tmpWorkshops);
    }

    const customSort = (a, b) => {
        if (a.slot.date < b.slot.date) {
            return -1;
        }
        if (a.slot.date > b.slot.date) {
            return 1;
        }

        if (a.startZeit < b.startZeit) {
            return -1;
        }
        if (a.startZeit > b.startZeit) {
            return 1;
        }

        return 0;
    }

    useEffect(() => {
        getWorkshops(anmeldung.workshops)
    }, [anmeldung])

    return (
        <Fragment>
            {anmeldung?.slots?.length > 0 ?
                <div className="kalender__anmeldung-item">
                    <div className="label">
                        {anmeldung.schule}, {anmeldung.ort}
                    </div>
                    <div>
                        {anmeldung.slots.length > ws.length ?
                            anmeldung.slots.map((slot) => <SlotItem slot={slot} key={slot.id} uuid={uuid} id={id} dataLoader={dataLoader} hideButtons={hideButtons} />) : null}
                        {ws.length > 0 ?
                            ws.map((workshop) => <WorkshopItem workshop={workshop} />) : null}
                    </div>
                </div>

                : null}
        </Fragment>
    )
}

AnmeldungItem.propTypes = {
    anmeldung: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataLoader: PropTypes.func.isRequired,
}