import React from 'react';
import { utils, writeFile } from 'xlsx'
import PropTypes from "prop-types";

import { useDirectus } from 'react-directus';


const getCurrentDateTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}-${hours}${minutes}`;
}


function CSVExport(props) {

    const { directus } = useDirectus();

    const getSlots = async (slotIds) => {
        const tmpSlotIds = slotIds.map((slot) => { return slot.id })
        const slots = await directus.items('slots').readByQuery({
            filter: {
                id: { _in: tmpSlotIds },
            },
            limit: 1000,
        });

        const preservedFields = slots.data.map((item) => {
            return {
                date: item.date,
                zeit: item.zeit,
            };
        });


        return preservedFields;
    }

    const getSlots2 = async (slotIds) => {
        const tmpSlotIds = slotIds.map((slot) => { return slot.id })
        const slots = await directus.items('slots').readByQuery({
            filter: {
                id: { _in: tmpSlotIds },
            },
            limit: 1000,
        });

        const preservedFields = slots.data.map((item) => {
            return {
                date: item.date,
                zeit: item.zeit,
                id: item.id
            };
        });


        return preservedFields;
    }

    const getSlotsByIds = async (slotIds) => {
        const tmpSlotIds = slotIds.map((slot) => { return slot.slots_id })
        const slots = await directus.items('slots').readByQuery({
            filter: {
                id: { _in: tmpSlotIds },
            },
            limit: 1000,
        });

        const preservedFields = slots.data.map((item) => {
            return {
                date: item.date,
                zeit: item.zeit,
            };
        });


        return preservedFields;
    }

    const getSlotsByIds2 = async (slotIds) => {
        const tmpSlotIds = slotIds.map((slot) => { return slot.slots_id })
        const slots = await directus.items('slots').readByQuery({
            filter: {
                id: { _in: tmpSlotIds },
            },
            limit: 1000,
        });

        return slots.data;
    }

    const getReports = async (anmeldungId) => {
        const reports = await directus.items('reports').readByQuery({
            filter: {
                anmeldungId: { _eq: anmeldungId },
            },
            limit: 1000,
            fields: '*,leiterpersonId.*'
        });

        return reports.data;
    }

    const getWorkshopReports = async (workshopIds) => {
        const reports = await directus.items('reports').readByQuery({
            filter: {
                workshopId: { _in: workshopIds },
            },
            limit: 1000,
        });

        return reports.data;
    }

    const getWorkshops = async (workshopIds) => {
        const workshops = await directus.items('workshops').readByQuery({
            filter: {
                id: { _in: workshopIds },
            },
            limit: 1000,
        });

        const preservedFields = workshops.data.map((item) => {
            return {
                bezeichnung: item.bezeichnung,
                anzahl_schueler: item.anzahl_schueler,
                inklusion: item.inklusion
            };
        });


        return preservedFields;
    }


    const fetchSlotsForItem = async (item) => {
        if (Array.isArray(item.slots) && item.slots.length !== 0) {
            item.slots = await getSlots(item.slots);
        }
        return item;
    };

    const fetchWorkshopsForItem = async (item) => {
        if (Array.isArray(item.workshops) && item.workshops.length !== 0) {
            item.workshops = await getWorkshops(item.workshops);
        }
        return item;
    };

    // new

    const fetchAnmeldungen = async () => {
        const anmeldungen = await directus.items('anmeldungen').readByQuery({ fields: '*,slots.*, workshops.*', limit: 1000, });

        const preservedFields = anmeldungen.data.map((item) => {

            const tmpItem = { ...item, ...JSON.parse(item.fields), workshops: item.workshops, slots: item.slots };

            const finanziellerSupport = item?.finanziellerSupport ? { ...JSON.parse(item.finanziellerSupport) } : {}


            let returnObj = {
                id: tmpItem.id,
                schule: tmpItem.schule,
                ort: tmpItem.ort,
                plz: tmpItem.plz,
                adresse: tmpItem.adresse,
                vorname: tmpItem.vorname,
                nachname: tmpItem.nachname,
                telefon: tmpItem.telefon,
                email: tmpItem.email,
                alternative_termine: tmpItem.alternative_termine,
                status: tmpItem.status,
                date_created: tmpItem.date_created,
                hub: tmpItem.hub?.name,
                slots: tmpItem.slots,
                anzahl_slots: tmpItem.slots.length,
                workshops: item.workshops,
                anzahl_workshops: item.workshops.length,
                inklusion: item.workshops.some((obj) => obj?.inklusion === 'true'),
            };

            if (item?.finanziellerSupport !== undefined) {
                returnObj = {
                    ...returnObj,
                    fixerBeitrag: finanziellerSupport?.fixerBeitrag,
                    supportBetrag: finanziellerSupport.supportBetrag,
                    proKind: finanziellerSupport.proKind,
                    anzahl_schueler: item.workshops.reduce((acc, obj) => acc + (parseInt(obj.anzahl_schueler) || 0), 0),
                    nochNicht: finanziellerSupport.nochNicht,
                }
            }

            return returnObj;
        });


        return preservedFields;
    }

    async function processAnmeldungen(anmeldungen) {
        const processedAnmeldungen = [];

        const combinedSlotsAndAnmeldungen = [];

        for (const item of anmeldungen) {
            if (item.slots && Array.isArray(item.slots) && item.slots.length > 0) {
                const fetchedSlots = await getSlotsByIds(item.slots);

                item.slots = fetchedSlots.map(slot => ({
                    slotDatum: slot.date,
                    slotZeit: slot.zeit
                }));

                item.slots.forEach(slot => {
                    combinedSlotsAndAnmeldungen.push({
                        id: item.id,
                        hub: item?.hub,
                        schule: item.schule,
                        ort: item.ort,
                        plz: item.plz,
                        adresse: item.adresse,
                        alternative_termine: item.alternative_termine,
                        anzahl_slots: item.slots.length,
                        slotDatum: slot.slotDatum,
                        slotZeit: slot.slotZeit,
                        status: item.status,

                    });
                });
            } else {
                item.slots = [];
            }

            processedAnmeldungen.push(item);
        }

        return { processedAnmeldungen, combinedSlotsAndAnmeldungen };
    }

    const getDaysFromSlots = (slots) => {
        const days = [];

        slots.forEach((slot) => {


            if (!days.includes(slot.date)) {
                days.push(slot.date);
            }
        });

        return days;
    }

    const getNameFromFields = (fields) => {
        const parsedFields = (fields !== undefined && fields !== '') ? JSON.parse(fields) : '';
        return `${parsedFields.vorname} ${parsedFields.nachname}`;

    }


    async function getReportsData(anmeldungen) {
        const reportsArray = [];

        for (const item of anmeldungen) {
            if (item.slots && Array.isArray(item.slots) && item.slots.length > 0) {
                const fetchedSlots = await getSlotsByIds2(item.slots);
                const tmpReports = await getReports(item.id);
                const reports = tmpReports.map((report) => {
                    return {
                        ...report,
                        fields: JSON.parse(report.fields)
                    }
                });

                console.log(item)

                const days = getDaysFromSlots(fetchedSlots);

                item.reports = reports;
                item.slots = fetchedSlots;
                item.days = days;

                reports.forEach((report) => {
                    const keys = Object.keys(report.fields.days)
                    const filteredKeys = keys.filter((key) => days.includes(key));
                    filteredKeys.forEach((key) => {
                        const obj = {
                            datum: key,
                            schule: item?.schule,
                            hub: item?.hub,
                            anzahl_km: report.fields.days[key]?.anzahl_km,
                            zeit: report.fields.days[key]?.dauer_min,
                            anzahl_workshops: report.fields.days[key]?.anzahl_workshops,
                            transportmittel: report.fields.days[key]?.transportmittel,
                            leiterperson: getNameFromFields(report.leiterpersonId?.fields),
                        }
                        reportsArray.push(obj);
                    })
                });

            } else {
                item.slots = [];

            }

            const allSlotsPassed = item.slots.every((slot) => {
                return new Date(slot.date) < new Date();
            });

        }

        return reportsArray;
    }

    const getDateFromSlots = (slots, id) => {
        if (slots?.length > 0) {
            const slot = slots?.find((slot) => slot.id === id);
            return slot.date;
        } else {
            return '';
        }

    }


    async function getReportsDataForWorkshops(anmeldungen) {
        const reportsArray = [];

        for (const item of anmeldungen) {
            if (item.workshops && Array.isArray(item.workshops) && item.workshops.length > 0) {
                let fetchedSlots = [];
                if (item.slots.length > 0) {
                    fetchedSlots = await getSlots2(item.slots);
                }
                const workshopIds = item.workshops.map((workshop) => workshop.id);
                const tmpReports = await getWorkshopReports(workshopIds);
                const reports = tmpReports.map((report) => {
                    return {
                        ...report,
                        fields: JSON.parse(report.fields)
                    }
                });





                reports.forEach((report) => {
                    const tmpWorkshop = item.workshops.find((workshop) => workshop.id === report.workshopId);
                    const obj = {
                        schule: item?.schule,
                        hub: item?.hub,
                        date: getDateFromSlots(fetchedSlots, tmpWorkshop?.slot),
                        bezeichnung: tmpWorkshop.bezeichnung,
                        startZeit: tmpWorkshop.startZeit,
                        stufe: tmpWorkshop.stufe,
                        anzahl_schueler: report.fields?.anzahl_schueler,
                        kommentar: report.fields?.kommentar,
                        anzahl_schueler_inklusion: report.fields?.anzahl_schueler_inklusion,
                        hilfsperson_vor_ort: report.fields?.hilfsperson_vor_ort,
                        sc_hilfsperson_vor_ort: report.fields?.sc_hilfsperson_vor_ort,
                        inklusion_kommentar: report.fields?.inklusion_kommentar,

                    }
                    reportsArray.push(obj);

                });



            }



        }

        return reportsArray;
    }


    const newExportData = async () => {
        const anmeldungen = await fetchAnmeldungen();

        const anmeldungenOverview = getAnmeldungenOverview(anmeldungen);

        const overview = anmeldungen.map(obj => ({ ...obj }));
        const anmeldungenMitWorkshops = anmeldungen.map(obj => ({ ...obj }));

        overview.forEach(obj => {
            delete obj.workshops;
            delete obj.slots;
        })

        const slotsTab = [];

        anmeldungen.forEach((item) => {
            if (item.slots && Array.isArray(item.slots)) {
                item.slots.forEach((slot, index) => {
                    const transformedItem = {
                        ...item,
                        slotDatum: slot.slotDatum,
                        slotZeit: slot.slotZeit,
                        status: item.status,
                    };

                    slotsTab.push(transformedItem);
                });
            } else {
                slotsTab.push(item);
            }
        });







        processAnmeldungen(anmeldungen).then(async ({ processedAnmeldungen, combinedSlotsAndAnmeldungen }) => {

            processedAnmeldungen.forEach(item => {
                if (item.slots && Array.isArray(item.slots)) {
                    item.slots.forEach((slot, index) => {
                        item[`slotDatum${index + 1}`] = slot.slotDatum;
                        item[`slotZeit${index + 1}`] = slot.slotZeit;

                    });
                }
                delete item.slots;
                delete item.workshops;
                delete item.inklusion;
                delete item.fixerBeitrag;
                delete item.proKind;
                delete item.nochNicht;
                delete item.supportBetrag;
            })

            anmeldungenMitWorkshops.forEach(item => {
                if (item.workshops && Array.isArray(item.workshops)) {
                    item.workshops.forEach((workshop, index) => {
                        item[`workshopBezeichnung${index + 1}`] = workshop.bezeichnung;
                        item[`workshopInklusion${index + 1}`] = workshop.inklusion;
                        item[`workshopInklusionInfo${index + 1}`] = workshop?.inklusion_info;
                        item[`workshopAnzahlSuS${index + 1}`] = workshop.anzahl_schueler;

                    });
                }
                delete item.slots;
                delete item.workshops;
                delete item.inklusion;
                delete item.fixerBeitrag;
                delete item.proKind;
                delete item.nochNicht;
                delete item.supportBetrag;
            })


            if (true) {

                const reports = await getReportsData(anmeldungenOverview);

                const workshopsReports = await getReportsDataForWorkshops(anmeldungenOverview);
                console.log(workshopsReports)


                let wb = utils.book_new(),
                    ws1 = utils.json_to_sheet(anmeldungenOverview),
                    ws2 = utils.json_to_sheet(processedAnmeldungen),
                    ws3 = utils.json_to_sheet(anmeldungenMitWorkshops),
                    ws4 = utils.json_to_sheet(combinedSlotsAndAnmeldungen),
                    ws5 = utils.json_to_sheet(reports),
                    ws6 = utils.json_to_sheet(workshopsReports)
                    ;
                utils.book_append_sheet(wb, ws1, "Übersichts")
                utils.book_append_sheet(wb, ws2, "Anmeldungen und Slots ")
                utils.book_append_sheet(wb, ws3, "Anmeldungen und Workshops ")
                utils.book_append_sheet(wb, ws4, "Slots")
                utils.book_append_sheet(wb, ws5, "Rapports")
                utils.book_append_sheet(wb, ws6, "Workshop Rapports")


                writeFile(wb, `bikecontrol-anmeldungen-${getCurrentDateTime()}.xlsx`)
            }
        });

    }

    const parseBooleanToJaNein = (value) => {
        if (value === 'FALSE' || value === false || value === 0) {
            return 'Nein';
        } else if (value === 'TRUE' || value === true || value === 1) {
            return 'Ja';
        } else {
            return value;
        }
    }

    const getAnmeldungenOverview = (anmeldungen) => {

        const overview = anmeldungen.map(obj => ({ ...obj }));
        overview.map(obj => {
            obj.fixerBeitrag = parseBooleanToJaNein(obj.fixerBeitrag);
            obj.inklusion = parseBooleanToJaNein(obj.inklusion);
            obj.nochNicht = parseBooleanToJaNein(obj.nochNicht);
            obj.proKind = parseBooleanToJaNein(obj.proKind);
            //delete obj.workshops;
        })
        return overview;
    }


    return (
        <div
            tabIndex={0}
            onClick={() => newExportData()}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    newExportData();
                }
            }}
            style={{ cursor: "pointer" }}
        >
            <img
                className={"export-button"}
                src={process.env.PUBLIC_URL + "/assets/Import.svg"}
                alt="arrow-filter"
            />
        </div>
    );
}

CSVExport.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CSVExport;
