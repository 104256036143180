import { Entries, Entry } from "@helpers/Entries";
import i18n from '../../../translation/i18n'

const t = i18n.t.bind(i18n);

const LeiterpersonObject = new Entry([
    { key: 'vorname', name: 'Vorname' },
    { key: 'nachname', name: 'Nachname' },
    { key: 'email', name: 'Email' },
    { key: 'ausbildung', name: 'Ausbildung', type: 'select' },
    { key: 'geburtsdatum', name: 'Geburtsdatum', type: 'birthdate' },
    { key: 'erste_ausbildung', name: 'Leiterperson seit', type: 'year' },
    { key: 'counter', name: 'Zugeteilte Workshops', type: 'number' },
]);

class LeiterpersonenEntries extends Entries {
    constructor(rawData) {
        super(rawData); // Call the constructor of the parent class

        this.attributes = LeiterpersonObject.getAttributes();
    }
}

export { LeiterpersonenEntries, LeiterpersonObject };