import React, { useReducer, useEffect } from 'react';
import { useDirectus } from 'react-directus';
import { useParams, useNavigate } from 'react-router-dom';
import { LeiterpersonObject } from '../helpers';

import { Textfield, Checkboxes, DateInput } from '@components';
import ButtonGroup from '../../../components/input-fields/button/ButtonGroup';
import { useError } from '../../../context/ErrorContext';

import { reducer } from '@helpers/general-helpers';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const initialState = LeiterpersonObject.getInitialState();

function AddLeiterperson(props) {

    const { isEditPage } = props;
    const { t } = useTranslation();

    let { id } = useParams();
    const navigate = useNavigate();
    const { directus } = useDirectus();
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const { setErrorMessage, setSuccessMessage } = useError();

    const deleteItem = async () => {
        await directus.items('leiterpersonen').deleteOne(id).then(
            (response) => {
                navigate('/app/leiterpersonen');
            }
        ).catch((e) => {
            setErrorMessage(e.message);
        }
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEditPage) {
            directus.items('leiterpersonen').updateOne(id, { fields: JSON.stringify(reducerState) }).then(
                (response) => {
                    setSuccessMessage('Leiterperson wurde erfolgreich aktualisiert.');
                }).catch((e) => {
                    setErrorMessage(e.message);
                })
        } else {

            directus.items('leiterpersonen').createOne({ fields: JSON.stringify(reducerState) }).then(
                (response) => {
                    setSuccessMessage('Leiterperson wurde erfolgreich erstellt.');
                    navigate(`/app/leiterpersonen/bearbeiten/${response.id}`)
                }).catch((e) => {
                    setErrorMessage(e.message);
                })
        }

    }

    useEffect(() => {
        if (id !== undefined && isEditPage) {
            directus.items('leiterpersonen').readOne(id).then(
                (response) => {
                    const fetchedData = JSON.parse(response.fields);
                    const mergedData = { ...initialState, ...fetchedData, uuid: response.uuid }
                    dispatch({
                        type: 'FETCH',
                        payload: mergedData
                    })

                }).catch((e) => {
                    setErrorMessage(e.message);
                })
        }
    }, [directus, id, isEditPage])

    const options =

        [
            {
                name: t("Grundausbildung"),
            },
            {
                name: t("Weiterbildung 1"),
            },
            {
                name: t('wb2'),
            },
            {
                name: t("Road Guide Basic"),
            },
            {
                name: t("MTB Guide Basic"),
            },
            {
                name: t("MTB Guide Advanced"),
            },
            {
                name: t('fachausweis'),
            }
        ];


    const requestAvailability = () => {
        const uuid = uuidv4();

        // update leiterperson item with directus
        directus.items('leiterpersonen').updateOne(id, { uuid }).then(
            (response) => {
                setSuccessMessage('Leiterperson wurde erfolgreich aktualisiert.');
            }).catch((e) => {
                setErrorMessage(e.message);
            })

    }

    const LEITERINFO_URL = 'https://app.swiss-cycling-bikecontrol.ch/v2/leiterpersonen'

    function copyLinkTopClipboard(uuid) {
        console.log(uuid)
        const text = LEITERINFO_URL + "?uuid=" + uuid + '&id=' + id;
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard');
            console.log(text)
            setSuccessMessage(t('Link erfolgreich in die Zwischenablage kopiert'));
        }).catch(err => {
            console.error('Link konnte nicht kopiert werden: ', err);
            setErrorMessage(t('Link konnte nicht kopiert werden: '));

        });


    }

    return (
        <div className={'base-container'}>
            <form onSubmit={e => { handleSubmit(e) }}>

                {LeiterpersonObject.getFields().map((obj) => {
                    if (obj.type === "select") {
                        return (
                            <div key={obj.key}>
                                <h2>{t('Ausbildung')}</h2>
                                {true ? <Checkboxes
                                    options={options}
                                    items={reducerState[obj.key]}
                                    dispatch={(value) =>
                                        dispatch({
                                            type: 'UPDATE',
                                            payload: value,
                                            key: obj.key,
                                        })}
                                /> : null}

                            </div>
                        );

                    }
                    if (obj.type === "birthdate" || obj.type === 'year') {
                        return (
                            <DateInput
                                key={obj.key}
                                type={obj.type}
                                value={reducerState[obj.key]}
                                onChange={(value) =>
                                    dispatch({
                                        type: 'UPDATE',
                                        payload: value,
                                        key: obj.key,
                                    })
                                } />

                        );

                    }

                    return <Textfield
                        className="small"
                        value={reducerState[obj.key]}
                        onChange={(value) =>
                            dispatch({
                                type: 'UPDATE',
                                payload: value,
                                key: obj.key,
                            })
                        }
                        placeholder={obj.name}
                        key={obj.key}
                    />
                })}
                <ButtonGroup deleteItem={deleteItem} />
            </form>
            <h2>{t('Verfügbarkeiten anfragen')}</h2>
            <p>{t('verfügbarkeit_text')}</p>
            <button class="button small" onClick={() => requestAvailability()}>{t('Verfügbarkeiten anfragen')}</button>
            <button style={{ marginLeft: 10 }} className="button small" onClick={() => copyLinkTopClipboard(reducerState.uuid)}>{t('Leiterinfo Link kopieren')}</button>

        </div>
    )
}

AddLeiterperson.defaultProps = {
    isEditPage: false,
};

AddLeiterperson.propTypes = {
    isEditPage: PropTypes.bool,
};

export default AddLeiterperson;