import React, { useEffect, useState } from 'react'
import { useDirectus } from 'react-directus';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { set } from 'date-fns';

export default function HubPreview(props) {
    const { hub } = props;
    const { directus } = useDirectus();
    const [slots, setSlots] = useState(null);
    const [workshops, setWorkshops] = useState(null);
    const [anmeldungen, setAnmeldungen] = useState(null);
    const { t } = useTranslation('dashboard');
    const [confirmedAnmeldungen, setConfirmedAnmeldungen] = useState(null);
    const [confirmedWorkshops, setConfirmedWorkshops] = useState(null);

    const [passedWorkshops, setPassedWorkshops] = useState(null);

    useEffect(() => {

        directus.items('slots').readByQuery({ limit: 1000, filter: { hub: { _eq: hub.id } } }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const number = response.data.length;
                setSlots(number);
            }).catch((e) => {
                console.log(e);
            })

        directus.items('anmeldungen').readByQuery({ limit: 1000, filter: { hub: { _eq: hub.id } } }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const number = response.data.length;
                setAnmeldungen(number);
                setConfirmedAnmeldungen(response.data.filter((anmeldung) => anmeldung.status === 'confirmed').length);
                const tmpConfirmedWorkshops = response.data.filter((anmeldung) => anmeldung.status === 'confirmed').map((anmeldung) => anmeldung.workshops).flat().filter((workshop) => workshop.slot !== null).length;
                setConfirmedWorkshops(tmpConfirmedWorkshops);
                console.log(response.data);
                const numberOfWorkshops = response.data.reduce((acc, anmeldung) => {
                    return acc + anmeldung.workshops.length;
                }, 0); // Initialize accumulator to 0
                console.log(numberOfWorkshops);
                const numberOfConfirmedWorkshops = response.data.filter((anmeldung) => anmeldung.status !== 'open').reduce((acc, anmeldung) => {
                    return acc + anmeldung.workshops.length;
                }, 0);
                console.log(numberOfConfirmedWorkshops);
                setWorkshops(numberOfWorkshops);
                setConfirmedWorkshops(numberOfConfirmedWorkshops);

            }).catch((e) => {
                console.log(e);
            })
        directus.items('workshops').readByQuery({ limit: 1000, filter: { hub: { _eq: hub.id } }, fields: "*,slot.date" }).then(
            (response) => {
                const currentDate = new Date();
                const filteredWorkshops = response.data.filter((workshop) => new Date(workshop?.slot?.date) < currentDate);
                setPassedWorkshops(filteredWorkshops.length);
            }).catch((e) => {
                console.log(e);
            })


    }, [hub])

    return (
        <div className="hub-preview">
            <div className="hub-preview__name">{JSON.parse(hub.fields).name} {hub?.isFullyBooked ? '- Anmeldefenster geschlossen' : ''}</div>
            <div className="grid-container">

                <div className="grid-item">
                    <span className="number">{anmeldungen}</span>
                    <span className="text">{t('Anzahl Anmeldungen')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{workshops}</span>
                    <span className="text">{t('Gebuchte Workshops')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{confirmedAnmeldungen}</span>
                    <span className="text">{t('Bestätigte Anmeldungen')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{confirmedWorkshops}</span>
                    <span className="text">{t('Bestätigte Workshops')}</span>
                </div>
                <div className="grid-item">
                    <span className="number">{passedWorkshops}</span>
                    <span className="text">{t('Durchgeführte Workshops')}</span>
                </div>
            </div>
        </div>
    )
}


HubPreview.propTypes = {
    hub: PropTypes.object.isRequired
};