import React, { useMemo, memo, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDirectus } from 'react-directus';
import { checkIfAllWorkshopsAreReported, checkIfAllDaysAreReported } from '../../pages/anmeldung-single/containers/reports/reports-helper';

const CustomLoader = () => <div>Loading...</div>;

const EmptyComponent = () => <div>No Data Available</div>;



const RapportStatusCell = ({ row }) => {
    const [rapportStatusText, setRapportStatusText] = useState('Loading...');
    const { directus } = useDirectus();

    const rapportCellStyle = (status) => {
        if (status === '') return {};
        return {
            color: status !== 'Rapport vollständig' ? '#FF681E' : '#069C56',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
        };
    };

    const rapportStatus = async (row) => {
        const workshopIds = row.workshops.map((workshop) => workshop.id);
        if (workshopIds.length === 0) return '';
        const workshopReports = await loadWorkshopReports(workshopIds);

        const reports = await loadReports(row.id);

        const slotIds = row.slots.map((slot) => slot.slots_id);
        let slots;
        if (slotIds.length > 0) {
            slots = await loadSlots(slotIds);
        } else {
            slots = [];
        }

        const allSlotsInFuture = (slots) => {
            const today = new Date();
            const tmpDates = slots.map((slot) => new Date(slot.date));
            return tmpDates.every((date) => date > today);
        }

        if (allSlotsInFuture(slots)) {
            return '';
        }

        const workshopRapportStatus = checkIfAllWorkshopsAreReported(row, workshopReports)
        const dayRapportStatus = checkIfAllDaysAreReported(slots, reports);

        if (workshopRapportStatus && dayRapportStatus.allDaysReported) {
            return 'Rapport vollständig';
        } else {
            return 'Rapport unvollständig';
        }
    }


    const loadWorkshopReports = async (workshopIds) => {
        try {
            const response = await directus.items('reports').readByQuery({ filter: { workshopId: { _in: workshopIds } } });
            const tmpData = response.data.map((report) => {
                const fields = JSON.parse(report.fields);
                return { workshopId: report.workshopId, ...fields };
            });
            return tmpData;
        } catch (e) {
            throw e;
        }
    };

    const loadReports = async (anmeldungId) => {
        try {
            const response = await directus.items('reports').readByQuery({ filter: { anmeldungId: { _eq: anmeldungId } } })
            if (response.data === undefined) return [];
            const tmpData = response.data.map((report) => {
                const fields = JSON.parse(report.fields);
                return { leiterpersonId: report.leiterpersonId, ...fields }
            })
            return tmpData;
        } catch (e) {
            throw e;
        }
    }

    const loadSlots = async (slotIds) => {
        try {
            const response = await directus.items('slots').readByQuery({ filter: { id: { _in: slotIds } }, fields: ['*'] });
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    useEffect(() => {
        const fetchRapportStatus = async () => {
            const status = await rapportStatus(row);
            setRapportStatusText(status);
        };

        fetchRapportStatus();
    }, [row]);

    return <div style={rapportCellStyle(rapportStatusText)}>{rapportStatusText}</div>;
};


const AnmeldungenTable = memo((props) => {
    const { entries, filter } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const statusCellStyle = (row) => {
        return {
            color: row.status !== 'open' ? '#069C56' : '#FF681E',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
        };
    };

    const zuteilungCellStyle = (row) => {
        return {
            color: row.zuteilung !== 'nicht zugeteilt' ? '#069C56' : '#FF681E',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
        };
    };


    const convertStatusToText = (status) => {
        if (status === 'open') {
            return 'offen'
        } else if (status === 'confirmed') {
            return 'bestätigt'
        } else if (status === 'completed') {
            return 'abgeschlossen'
        } else {
            return status
        }
    }


    const columns = useMemo(() => {

        const tmpEntries = entries.getTableColumns(filter).map(column => {
            if (column.key === 'status') {
                return {
                    ...column,
                    cell: (row) => <div style={statusCellStyle(row)}>{convertStatusToText(row.status)}</div>,
                };
            }
            if (column.key === 'zuteilung') {
                return {
                    ...column,
                    cell: (row) => <div style={zuteilungCellStyle(row)}>{convertStatusToText(row.zuteilung)}</div>,
                };
            }
            return column;
        });

        tmpEntries.push({
            key: 'rapport',
            name: 'Rapport',
            cell: (row) => <RapportStatusCell row={row} />,
        });

        return tmpEntries;
    }, [entries, filter]);






    const handleRowClick = (row) => {
        navigate(`bearbeiten/${row.id}`);
    };

    return (
        <DataTable
            columns={columns}
            data={entries.getData()}
            highlightOnHover
            keyField="id"
            defaultSortField="id"
            defaultSortDesc
            responsive
            onRowClicked={handleRowClick}
            progressPending={entries.loading}
            progressComponent={<CustomLoader />}
            noDataComponent={<EmptyComponent />}
        />
    );
});

AnmeldungenTable.propTypes = {
    entries: PropTypes.shape({
        getTableColumns: PropTypes.func.isRequired,
        getData: PropTypes.func.isRequired,
        loading: PropTypes.bool,
    }).isRequired,
    filter: PropTypes.string.isRequired,
};

export { AnmeldungenTable };
