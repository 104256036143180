import React, { Fragment } from 'react'
import SlotItem from './SlotItem';

import PropTypes from 'prop-types'
export default function AnmeldungItem(props) {
    const { anmeldung, uuid, id, dataLoader, hideButtons } = props;

    console.log(anmeldung)
    return (
        <Fragment>
            {anmeldung?.slots?.length > 0 ?
                <div className="kalender__anmeldung-item">
                    <div className="label">
                        {anmeldung.schule}, {anmeldung.ort}
                    </div>
                    <div>
                        {anmeldung?.slots?.length > 0 ?
                            anmeldung?.slots.map((slot) => <SlotItem slot={slot} key={slot.id} uuid={uuid} id={id} dataLoader={dataLoader} hideButtons={hideButtons} />) : null}
                    </div>
                </div>

                : null}
        </Fragment>
    )
}

AnmeldungItem.propTypes = {
    anmeldung: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataLoader: PropTypes.func.isRequired,
}