import React, { useState, useEffect } from 'react'
import { formatDate, capitalize } from '@helpers';
import PropTypes from 'prop-types';
import './leiterperson-zuweisung.scss';
import { useDirectus } from 'react-directus';
import { useTranslation } from 'react-i18next';

export default function SlotItem(props) {
    const { slot, leiterpersonen } = props;
    const { directus } = useDirectus();
    const { t } = useTranslation('anmeldung');

    const [checked, setChecked] = useState([]);

    const handleOnChange = (e, leiterpersonId) => {
        if (e.target.checked) {
            updateLeiterpersonen([...checked, leiterpersonId])
            setChecked([...checked, leiterpersonId]);
        } else {
            updateLeiterpersonen(checked.filter((id) => id !== leiterpersonId))
            setChecked(checked.filter((id) => id !== leiterpersonId));
        }
    }

    const updateLeiterpersonen = async (newChecked) => {

        const response = await directus.items('slots').updateOne(slot.id, {
            leiterpersonen: JSON.stringify(newChecked)
        });
    }

    useEffect(() => {
        if (slot.leiterpersonen) {
            setChecked(JSON.parse(slot.leiterpersonen));
        }
    }, []);

    const getNumberOfUniqueItems = (str, key = 'available') => {
        const obj = JSON.parse(slot.availability);
        const arr = obj[key];
        const uniqueItems = new Set(arr).size;
        return uniqueItems;
    }

    return (
        <div className="kalender__workshop-item">
            <div>
                <div className="date">{`${formatDate(slot.date)}, ${slot.kontingent} Workshops`}</div>
                <div className="zeit">{capitalize(slot.zeit)}</div>
            </div>
            <div className="leiterpersonen-wrapper">
                {leiterpersonen.map((leiterperson) => {
                    const isAvailable = JSON.parse(slot.availability) && JSON.parse(slot.availability).available.includes(leiterperson.id);
                    const isNotAvailable = JSON.parse(slot.availability) && JSON.parse(slot.availability).unavailable.includes(leiterperson.id);

                    const isChecked = checked.includes(leiterperson.id);
                    return (
                        <div className={`leiterperson ${isAvailable ? 'available' : null} ${isNotAvailable ? 'unavailable' : null}`} key={leiterperson.id}>
                            <input
                                type="checkbox"
                                id={`checkbox-${slot.id}-${leiterperson.id}`}
                                name={leiterperson.vorname}
                                checked={isChecked}
                                // Add an onChange handler if you need to handle changes
                                onChange={(e) => handleOnChange(e, leiterperson.id)}
                            />
                            <label htmlFor={`checkbox-${slot.id}-${leiterperson.id}`}>
                                {leiterperson.vorname} {leiterperson.nachname}
                            </label>
                        </div>
                    );
                })}
            </div>


            <div>
                <div className="button">
                    {JSON.parse(slot.availability) && JSON.parse(slot.availability).available
                        ? getNumberOfUniqueItems(slot.availability) !== 1
                            ? getNumberOfUniqueItems(slot.availability) + t(' Leiterpersonen verfügbar')
                            : getNumberOfUniqueItems(slot.availability) + t(' Leiterperson verfügbar')
                        : 0 + ' ' + t('Leiterpersonen verfügbar')
                    }
                </div>
                <div className="button not">
                    {JSON.parse(slot.availability) && getNumberOfUniqueItems(slot.availability, 'unavailable') + t(' Absagen')
                        ? getNumberOfUniqueItems(slot.availability, 'unavailable') + t(' Absagen')
                        : 0 + t(' Absagen')
                    }

                </div>
            </div>
        </div>
    )

}

SlotItem.propTypes = {
    slot: PropTypes.object.isRequired,
}