import React, { useEffect, useState } from 'react'
import { formatDate, capitalize } from '@helpers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDirectus } from 'react-directus';

export default function SlotItem(props) {
    const { slot } = props;
    const { t } = useTranslation('dashboard');
    const { directus } = useDirectus();

    const [leiterpersonen, setLeiterpersonen] = useState([]);

    const getLeiterpersonen = async (ids) => {

        try {
            const response = await directus.items('leiterpersonen').readByQuery({
                filter: { id: { _in: ids } },
                fields: ['*']
            });
            const tmpLeiterersonen = response.data.map((leiterperson) => { return { id: leiterperson.id, ...JSON.parse(leiterperson.fields) } });
            console.log(tmpLeiterersonen)
            setLeiterpersonen(tmpLeiterersonen);
        } catch (e) {
            console.error('Error fetching slots:', e);
        }
    }


    useEffect(() => {
        const tmpLeiterpersonen = JSON.parse(slot?.leiterpersonen);
        if (tmpLeiterpersonen?.length > 0) {
            getLeiterpersonen(tmpLeiterpersonen);

        }
    }, [slot])

    const getNumberOfUniqueItems = (str, key = 'available') => {
        const obj = JSON.parse(slot.availability);
        const arr = obj[key];
        const uniqueItems = new Set(arr).size;
        return uniqueItems;
    }

    return (
        <div className="kalender__workshop-item">
            <div>
                <div className="date">{formatDate(slot.date)} {slot?.kontingent === '4' ? "- Doppellektion" : ''}</div>
                <div className="zeit">{capitalize(slot.zeit)}</div>
                <div><i></i></div>
            </div>        {leiterpersonen?.length > 0 ?
                <div>

                    {leiterpersonen.map((leiterperson) => <div className="button" key={leiterperson.id}>{leiterperson.vorname} {leiterperson.nachname}</div>)}

                </div> :
                <div>
                    <div className="button">
                        {JSON.parse(slot.availability) && JSON.parse(slot.availability).available
                            ? getNumberOfUniqueItems(slot.availability) !== 1
                                ? getNumberOfUniqueItems(slot.availability) + t(' Leiterpersonen verfügbar')
                                : getNumberOfUniqueItems(slot.availability) + t(' Leiterperson verfügbar')
                            : 0 + ' ' + t('Leiterpersonen verfügbar')
                        }
                    </div>
                    <div className="button not">
                        {JSON.parse(slot.availability) && getNumberOfUniqueItems(slot.availability, 'unavailable') + t(' Absagen')
                            ? getNumberOfUniqueItems(slot.availability, 'unavailable') + t(' Absagen')
                            : 0 + t(' Absagen')
                        }

                    </div>
                </div>}
        </div>
    )

}

SlotItem.propTypes = {
    slot: PropTypes.object.isRequired,
}