import { useState, useEffect } from 'react';
import { useSearchParams, Link } from "react-router-dom";
import { useDirectus } from 'react-directus';


import { Textfield, Button } from '../../components';


import './reset-password.scss';


const LoginForm = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [token, setToken] = useState(null);
    const [err, setErr] = useState(null);
    const [success, setSuccess] = useState(false);

    const { directus } = useDirectus();

    const [searchParams] = useSearchParams();

    const { invitation } = props;


    useEffect(() => {
        setToken(searchParams.get('token'));
    }, [searchParams])



    const handleSubmit = (e) => {
        e.preventDefault();
        if (token !== null) {
            if (password !== passwordConfirmation) {
                setErr('Passwörter müssen identisch sein');
                setPassword('');
                setPasswordConfirmation('');
                return;
            }
            if (invitation) {
                directus.users.invites.accept(token, password).then(
                    () => {
                        setErr("Account erfolgreich erstellt!");
                        setSuccess(true);

                    }).catch((e) => {
                        setErr(e.message);

                    })
            } else {
                directus.auth.password.reset(token, password).then(
                    () => {
                        setErr("Passwort erfolgreich zurückgesetzt!");
                        setSuccess(true);
                    }).catch((e) => {
                        setErr(e.message);

                    })
            }

        } else if (email !== '') {
            directus.auth.password.request(email, 'https://bikecontrol.dkueffer.cyon.site/password-reset').then(
                () => {
                    setErr("Falls diese Email existiert, bekommst du eine Mail von uns.");
                }).catch((e) => {
                    setErr(e.message);

                })
        }



    }


    return <div className='login-form'>
        <h1>{invitation ? 'Account erstellen' : 'Passwort reset'}</h1>
        <form onSubmit={e => { handleSubmit(e) }}>
            {token !== null ?
                <div>
                    <Textfield value={password} onChange={setPassword} placeholder="Neues Passwort" type="password" />
                    <Textfield value={passwordConfirmation} onChange={setPasswordConfirmation} placeholder="Neues Passwort bestätigen" type="password" />
                    {err ? <div className="error-message">{err}</div> : null}
                    {success ? <Link className="button" to="/login">zur Login-Seite</Link> : <Button
                        type='submit'
                        value={invitation ? 'Speichern' : 'Reset'}
                    />}

                </div>
                :
                <div>
                    <Textfield value={email} onChange={setEmail} placeholder="Email" />
                    {err ? <div className="error-message">{err}</div> : null}

                    <Button
                        type='submit'
                        value='Link senden'
                    />
                </div>
            }


        </form>

    </div>;
};

export default LoginForm;
