import React from 'react'
import { formatDate, getLeiterpersonName, getReportEntries, checkIfAllDaysAreReported } from './reports-helper';
import { useTranslation } from 'react-i18next';

export default function ReiseTable(props) {

    const { singleDays, reports, slots, leiterpersonen } = props;
    const { t } = useTranslation('anmeldung');
    return (
        <div className="mb-10">
            <h2>{t('Hin-/Rückfahrt')} {checkIfAllDaysAreReported(slots, reports).allDaysReported ? <span className="info-button">{t('alle rapportiert')}</span> : <span className="info-button not">{t('noch nicht alle rapportiert')}</span>}</h2>
            <div className="table">
                <div className="row headline">
                    <span>{t('Datum')}</span>
                    <span>{t('Leiterperson')}</span>
                    <span>{t('Kilometer')}</span>
                    <span>{t('Zeit')}</span>
                    <span>{t('Transportmittel')}</span>
                    <span>{t('Anzahl Workshops')}</span>

                </div>
                {singleDays.map((day) => {
                    const entries = getReportEntries(reports, day);
                    if (entries.length === 0) {
                        return (
                            <div key={day} className="row">
                                <span>{formatDate(day)}</span>
                                <span>-</span>
                            </div>
                        );
                    } else {
                        return entries.map((entry) => {
                            return (
                                <div key={entry.leiterpersonId} className="row">
                                    <span>{formatDate(day)}</span>
                                    <span>{getLeiterpersonName(entry.leiterpersonId, leiterpersonen)}</span>
                                    <span>{entry.report.anzahl_km}</span>
                                    <span>{entry.report.dauer_min}</span>
                                    <span>{entry.report.transportmittel}</span>
                                    <span>{entry.report.anzahl_workshops}</span>

                                </div>
                            );
                        });
                    }
                })}
            </div>
        </div>
    )
}
