import React, { useEffect, useState } from 'react';

import { formatDate, getLeiterpersonName, checkIfAllDaysAreReported } from './reports-helper';
import { useTranslation } from 'react-i18next';

export default function OffeneReports(props) {
    const [notReported, setNotReported] = useState({});
    const { slots, reports, leiterpersonen } = props;
    const { t } = useTranslation('anmeldung');

    useEffect(() => {
        const result = checkIfAllDaysAreReported(slots, reports);
        console.log(result.notReported, 'result.notReported')
        setNotReported(result.notReported || {});
    }, [slots, reports]);

    return (
        <div className="mb-30">
            {Object.keys(notReported).length > 0 ? (
                <>
                    <h3>{t('Noch offen')}:</h3>
                    {Object.keys(notReported).map((key) => (
                        <div key={key} className="row">
                            <span>{getLeiterpersonName(parseInt(key), leiterpersonen)}: </span>
                            {notReported[key].map((day, index) => (
                                <React.Fragment key={day + index}>
                                    {index > 0 ? ", " : ""}{formatDate(day)}
                                </React.Fragment>
                            ))}
                        </div>
                    ))}
                </>
            ) : null}
        </div>
    );
}
