import React, { useEffect, useState } from 'react';
import SlotItem from './SlotItem';
import { useDirectus } from 'react-directus';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function AnmeldungItem({ anmeldung }) {
    const { directus } = useDirectus();
    const [slots, setSlots] = useState([]);

    const getSlots = async (slotIds) => {
        const filteredSlotIds = slotIds.filter(item => item.slots_id).map(item => item.slots_id);

        if (filteredSlotIds.length === 0) return;

        try {
            const response = await directus.items('slots').readByQuery({
                filter: { id: { _in: filteredSlotIds } },
                fields: ['*']
            });
            const tmpSlots = sortSlots(response.data)
            setSlots(tmpSlots);
        } catch (e) {
            console.error('Error fetching slots:', e);
            // Handle error appropriately
        }
    }

    const statusCellStyle = (anmeldung) => {
        return {
            color: anmeldung.status !== 'open' ? '#069C56' : '#FF681E',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
            marginLeft: 10
        };
    };

    const convertStatusToText = (status) => {
        if (status === 'open') {
            return 'offen'
        } else if (status === 'confirmed') {
            return 'bestätigt'
        } else {
            return status
        }
    }

    const zuteilungCellStyle = (anmeldung) => {
        return {
            color: anmeldung.zuteilung !== 'nicht zugeteilt' ? '#069C56' : '#FF681E',
            borderRadius: 5,
            border: '2px solid currentColor',
            paddingLeft: '4px',
            paddingRight: '4px',
            lineHeight: 1.5,
            fontWeight: 500,
            marginLeft: 5

        };
    };

    const getZuteilungStatus = (item) => {

        const allSlotsNotNull = item.workshops.every((workshop) => workshop.slot !== null);

        if (allSlotsNotNull) {
            return 'zugeteilt';
        } else {
            return 'nicht zugeteilt';
        }

    }


    const sortSlots = (slots) => {
        slots.sort((a, b) => {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;

            return zeitOrder[a.zeit] - zeitOrder[b.zeit];
        });

        return slots;
    }

    const zeitOrder = {
        "vormittag": 1,
        "nachmittag": 2
        // Add other possible values for 'zeit' here with their order
    };

    useEffect(() => {
        getSlots(anmeldung.slots);
    }, [anmeldung.slots, directus.items]); // Assuming anmeldung.slots and directus.items are stable

    return (
        <Link to={`/app/anmeldungen/bearbeiten/${anmeldung.id}`}>
            {slots.length > 0 && (
                <div className="kalender__anmeldung-item">
                    <div className="label">
                        {anmeldung.schule}, {anmeldung.ort}
                        <span style={statusCellStyle(anmeldung)}>{convertStatusToText(anmeldung.status)}</span>
                        <span style={zuteilungCellStyle(anmeldung)}>{convertStatusToText(getZuteilungStatus(anmeldung))}</span>
                    </div>
                    <div>
                        {slots.map((slot) => <SlotItem slot={slot} key={slot.id} />)}
                    </div>

                </div>
            )}
        </Link>
    );
}

AnmeldungItem.propTypes = {
    anmeldung: PropTypes.object.isRequired,
};
