import { useEffect, useState } from 'react';
import './dashboard.scss';
import { useDirectus } from 'react-directus';
import Kalender from './components/Kalender';
import HubPreview from './components/HubPreview';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {

    const { directus } = useDirectus();
    const [slots, setSlots] = useState(null);
    const [anmeldungen, setAnmeldungen] = useState(null);
    const [confirmedAnmeldungen, setConfirmedAnmeldungen] = useState(null);
    const [confirmedWorkshops, setConfirmedWorkshops] = useState(null);
    const [workshops, setWorkshops] = useState(null);
    const [hubs, setHubs] = useState(null);
    const { t } = useTranslation('dashboard');
    const [role, setRole] = useState(null);

    const [passedWorkshops, setPassedWorkshops] = useState(null);

    useEffect(() => {

        directus.items('hubs').readByQuery({ limit: 1000 }).then(
            (response) => {
                setHubs(response.data);
            }).catch((e) => {
                console.log(e);
            })
        directus.items('slots').readByQuery({ limit: 10000 }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const numberOfSlots = response.data.length;
                setSlots(numberOfSlots);
            }).catch((e) => {
                console.log(e);
            })

        directus.items('anmeldungen').readByQuery({ limit: 10000 }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const numberOfSlots = response.data.length;
                setAnmeldungen(numberOfSlots);
                setConfirmedAnmeldungen(response.data.filter((anmeldung) => anmeldung.status === 'confirmed').length);
                const tmpConfirmedWorkshops = response.data.filter((anmeldung) => anmeldung.status === 'confirmed').map((anmeldung) => anmeldung.workshops).flat().filter((workshop) => workshop.slot !== null).length;
                setConfirmedWorkshops(tmpConfirmedWorkshops);


            }).catch((e) => {
                console.log(e);
            })
        directus.items('workshops').readByQuery({ limit: 1500, fields: '*,slot.date' }).then(
            (response) => {
                // Access the 'data' property of the response and use 'length' to get the number of slots
                const numberOfSlots = response.data.length;
                setWorkshops(numberOfSlots);

                const currentDate = new Date();
                const filteredWorkshops = response.data.filter((workshop) => new Date(workshop?.slot?.date) < currentDate);
                setPassedWorkshops(filteredWorkshops.length);
            }).catch((e) => {
                console.log(e);
            })

        directus.users.me.read({ fields: ['*', 'role.*'] }).then((response) => {
            console.log(response);
            setRole(response.role.name);
        }).catch((e) => {
            console.log(e);
        })

    }, [])


    return <div className="dashboard-page">
        {role !== 'Basic' ?
            <div className="hub-preview">
                <div className="hub-preview__name">{t('Total')}</div>
                <div className="grid-container">

                    <div className="grid-item">
                        <span className="number">{anmeldungen}</span>
                        <span className="text">{t('Anzahl Anmeldungen')}</span>
                    </div>
                    <div className="grid-item">
                        <span className="number">{workshops}</span>
                        <span className="text">{t('Gebuchte Workshops')}</span>
                    </div>
                    <div className="grid-item">
                        <span className="number">{confirmedAnmeldungen}</span>
                        <span className="text">{t('Bestätigte Anmeldungen')}</span>
                    </div>
                    <div className="grid-item">
                        <span className="number">{confirmedWorkshops}</span>
                        <span className="text">{t('Bestätigte Workshops')}</span>
                    </div>
                    <div className="grid-item">
                        <span className="number">{passedWorkshops}</span>
                        <span className="text">{t('Durchgeführte Slots')}</span>
                    </div>
                </div>
            </div>
            : null}
        {hubs?.length > 0 ? hubs.map((hub) => <HubPreview hub={hub} />) : null}

        <div>

            <Kalender />
        </div>
    </div>;
};

export default Dashboard;