import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function AnmeldungBestaetigen(props) {

    const { status, confirmAnmeldung, setPersonalMessage, personalMessage } = props;
    const { t } = useTranslation('anmeldung');


    return (
        <div >
            {status !== 'confirmed' && status !== 'completed' ?

                <div className="overview-wrapper">
                    <h2>{t('Termine bestätigen')}</h2>

                    <div className={'input-wrapper personal-message'}>
                        <textarea
                            disabled={status === 'confirmed'}
                            value={personalMessage || ''}
                            onChange={(e) =>
                                setPersonalMessage(e.target.value)
                            }
                            placeholder={t('Persönliche Bestätigungsnachricht an die Schule.')}
                        />
                    </div>
                    <div className="message-box-wrapper inline">
                        <div className="message-box warning active confirm-submission">
                            <span>{t('anmeldung_noch_nicht_bestaetigt')} </span>
                            <button onClick={() => confirmAnmeldung()} className="confirm-submission__button">{t('Anmeldung bestätigen')}</button>
                        </div>
                    </div>
                </div>
                : null

            }</div>
    )
}

AnmeldungBestaetigen.propTypes = {
    status: PropTypes.string.isRequired,
    confirmAnmeldung: PropTypes.func.isRequired,
    setPersonalMessage: PropTypes.func.isRequired,
    personalMessage: PropTypes.string.isRequired
};