import React from 'react'
import { checkIfAllWorkshopsAreReported, getWorkshopById } from './reports-helper';
import { useTranslation } from 'react-i18next';

export default function WorkshopsTable(props) {
    const { data, workshopReports } = props;
    const { t } = useTranslation('anmeldung');

    return (
        <div className="mb-30 mt-20">
            <h2>{t('Workshops')} {checkIfAllWorkshopsAreReported(data, workshopReports) ? <span className="info-button">{t('alle rapportiert')}</span> : <span className="info-button not">{t('noch nicht alle rapportiert')}</span>}</h2>
            <div className="table">
                <div className="row headline">
                    <span>{t('Bezeichnung')}</span>
                    <span>{t('Anzahl SuS')}</span>
                    <span>{t('mit Beeinträchtigung')}</span>
                    <span>{t('Kommentar')}</span>
                </div>
                {data.workshops.map((workshop) => {
                    return (
                        <div key={workshop.id} className="row">
                            <span>{workshop?.bezeichnung}</span>
                            <span>{getWorkshopById(workshop.id, workshopReports)?.anzahl_schueler}</span>
                            <span>{getWorkshopById(workshop.id, workshopReports)?.anzahl_schueler_inklusion}</span>
                            <span>{getWorkshopById(workshop.id, workshopReports)?.kommentar}</span>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}
