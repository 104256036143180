import React, { useState } from 'react';
import './change-password.scss';
import { useDirectus } from 'react-directus';


import { Button, Textfield } from '../../components';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {

    const { directus } = useDirectus();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [err, setErr] = useState(null);
    const { t } = useTranslation();


    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === '') {
            setErr(t('Bitte Passwort eingeben'));
            return;
        }
        if (password !== passwordConfirmation) {
            setErr(t('Passwörter müssen identisch sein'));
            setPassword('');
            setPasswordConfirmation('');
            return;
        }

        directus.users.me.update({ password: password }).then(
            (response) => {
                setPassword('');
                setPasswordConfirmation('');
                setErr(t('Passwort wurde erfolgreich geändert.'));

            }).catch((e) => {
                setErr(e.message);

            })


    }

    return (
        <div className={'change-password'}>
            <form onSubmit={e => { handleSubmit(e) }}>
                <Textfield className="small" value={password} onChange={setPassword} placeholder={t("Passwort")} type="password" />
                <Textfield className="small" value={passwordConfirmation} onChange={setPasswordConfirmation} placeholder={t("Passwort bestätigen")} type="password" />
                {err ? <div className="error-message">{err}</div> : null}

                <Button
                    type='submit'
                    value={t('Passwort ändern')}
                    className='small'
                />
            </form>
        </div>
    )
}
