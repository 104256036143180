import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import TimeInput from '../../../components/input-fields/date/Time';

import './workshop-item.scss';

export default function WorkshopItem2({ workshop, index, className, options, dateOptions, handleSelectChange, value, dispatch }) {
    const [selectedOption, setSelectedOption] = useState(null);
    const { t } = useTranslation('anmeldung');
    const [useManualTime, setUseManualTime] = useState(false);
    const [time, setTime] = useState("");

    const onSelectChange = (event) => {
        handleSelectChange(event, workshop.id);
        setSelectedOption(event.target.value);
        console.log(event.target.value)
        dispatch({
            type: 'UPDATE_VALUE',
            payload: {
                id: workshop.id,
                value: event.target.value
            }
        });
        dispatch({
            type: 'UPDATE_TIME',
            payload: {
                id: workshop.id,
                value: event.target.value
            }
        });
        dispatch({
            type: 'UPDATE_SLOT',
            payload: {
                id: workshop.id,
                value: event.target.value
            }
        });
    };

    const onCheckboxChange = () => {
        if (useManualTime) {
            dispatch({
                type: 'UPDATE_VALUE',
                payload: {
                    id: workshop.id,
                    value: selectedOption
                }
            });
            dispatch({
                type: 'UPDATE_TIME',
                payload: {
                    id: workshop.id,
                    value: undefined
                }
            });
        }
        setUseManualTime(!useManualTime);

    };

    useEffect(() => {


        const selectedOption = options.find(option => option.workshop === index);

        if (selectedOption && selectedOption.value) {
            setSelectedOption(selectedOption.value);
        }
    }, [options, index]);



    useEffect(() => {
        if (value) {
            const time = value.split('-')[1];

            if (time !== '0830' && time !== '1015' && time !== '1330' && time !== '1515' && time !== '') {
                let newTime = '1515'
                if (parseInt(time) < 930) {
                    newTime = '0830'

                } else if (parseInt(time) < 1200) {
                    newTime = '1015'

                } else if (parseInt(time) < 1430) {
                    newTime = '1330'

                }

                let tmpSelectedOption = value.split('-')[0] + '-' + newTime;
                const tmpOption = options.find(option => option.value === tmpSelectedOption);
                if (tmpOption?.workshop !== undefined) {
                    tmpSelectedOption += '-2';
                }

                setSelectedOption(tmpSelectedOption)
                setUseManualTime(true)
                setTime(time.substring(0, 2) + ":" + time.substring(2, 4))

            } else {
                setSelectedOption(value);
            }


        }
    }, [value]);

    useEffect(() => {
        console.log(selectedOption)
    }, [selectedOption])

    const handleTimeChange = (value) => {
        setTime(value);
        dispatch({
            type: 'UPDATE_TIME',
            payload: {
                id: workshop.id,
                time: value
            }
        });
        dispatch({
            type: 'UPDATE_SLOT',
            payload: {
                id: workshop.id,
                time: selectedOption
            }
        });
    };

    return (
        <div key={'workshop-' + workshop?.id} className={`workshop-preview2 ${className}`}>
            <div className="workshop-preview__title">{t('Workshop')} {index + 1}: {workshop?.bezeichnung}</div>
            <div className="workshop-preview2--select">

                <div className={'input-wrapper ' + className}> <select
                    id={`"selectInput" + ${index}`}
                    value={selectedOption}
                    onChange={onSelectChange}
                >
                    {options
                        .filter(option => option.workshop === workshop.id || option.workshop === undefined || option.value === '')
                        .map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </select>
                    <label>{t('Zeitfenster')}</label>
                </div>

                {!useManualTime ? null
                    :
                    <TimeInput onChange={handleTimeChange} value={time} />

                }
            </div>

            <div className="workshop-preview2--checkbox">
                <label>
                    <input
                        type="checkbox"
                        checked={useManualTime}
                        onChange={onCheckboxChange}
                    />
                    {t('Startzeit manuell überschreiben')}
                </label>
            </div>
        </div>
    );
}