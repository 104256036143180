import React, { useEffect, useState } from 'react'
import { useDirectus } from 'react-directus';

import SlotItem from '@components/slots/SlotItem';
import { useTranslation } from 'react-i18next';

export default function Slots(props) {
    const { slotIds } = props;
    const { directus } = useDirectus();

    const [slots, setSlots] = useState([]);
    const { t } = useTranslation('anmeldung');

    const sortSlots = (slots) => {
        slots.sort((a, b) => {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;

            return zeitOrder[a.zeit] - zeitOrder[b.zeit];
        });

        return slots;
    }

    const zeitOrder = {
        "vormittag": 1,
        "nachmittag": 2
        // Add other possible values for 'zeit' here with their order
    };


    useEffect(() => {
        if (slotIds.length > 0) {
            directus.items('slots').readMany(slotIds).then(
                (response) => {
                    const tmpSlots = sortSlots(response.data)
                    setSlots(tmpSlots);
                }).catch((e) => {
                    console.log(e);
                })
        }

    }, [slotIds])


    return (
        <div className="slot-selector slot-selector--preview">
            <h2>{t('Termine')}</h2>

            {slots.map((slot, index) => (
                <SlotItem slot={slot} key={slot.id} />
            ))}
        </div>
    )
}
