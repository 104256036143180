import React, { useState, useEffect } from 'react';
import { useDirectus } from 'react-directus';
import { useParams } from 'react-router-dom';
import { useError } from '@context/ErrorContext';

import PropTypes from 'prop-types';

import { Slots, NextSteps, Standort, HistoryItems, AnmeldungBestaetigen } from '../components';


import EditSlotsModal from '../containers/edit-slots-modal/EditSlotsModal';

import './anmeldung-preview.scss';

import WorkshopDetails from './workshop-details-modal/WorkshopDetails';
import ToDoItem from './ToDoItem';
import LeiterpersonenZuteilung from './leiterpersonen-zuteilung/LeiterpersonenZuteilung';
import { useTranslation } from 'react-i18next';
import DetailsAnfragen from '../_bak/DetailsAnfragen'

import Reports from './reports/Reports';

const WORDPRESS_TODOS_URL = process.env.REACT_APP_WORDPRESS_TODOS


function AnmeldungPreview(props) {
    const { directus } = useDirectus();
    let { id } = useParams();
    const { setErrorMessage, setSuccessMessage } = useError();
    const { t } = useTranslation('anmeldung');


    const { isEditPage } = props;

    const [data, setData] = useState(null);
    const [personalMessage, setPersonalMessage] = useState(null);

    const loadData = () => {
        directus.items('anmeldungen').readOne(id, { fields: ['*', 'slots.slots_id', 'workshops.*', 'workshop.slot.*'] }).then(
            (response) => {
                const fetchedData = response;
                fetchedData.slots = fetchedData.slots.map((slot) => slot.slots_id);

                const { fields, ...rest } = fetchedData;

                console.log(fetchedData)

                setData({
                    ...rest,
                    ...JSON.parse(fields),
                    slots: fetchedData.slots,
                    workshops: fetchedData.workshops
                });

            }).catch((e) => {
                setErrorMessage(t('fehler_beim_laden'));
            })

    }


    useEffect(() => {

        if (id !== undefined && isEditPage) {
            loadData();
        }
    }, [id])



    const confirmAnmeldung = () => {

        directus.items('anmeldungen').updateOne(id, {
            personal_message: personalMessage,
            status: 'confirmed',
            history: JSON.stringify([{ date: new Date(), message: 'Anmeldung bestätigt' }])
        }).then((response) => {
            setSuccessMessage(t('Anmeldung erfolgreich bestätigt'));
            loadData();

        }).catch((e) => {
            setErrorMessage(t('Anmeldung konnte nicht bestätigt werden'));
        })
    }


    function copyLinkTopClipboard(uuid) {
        const text = WORDPRESS_TODOS_URL + "?uuid=" + uuid;
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard');
            console.log(text)
            setSuccessMessage(t('Link erfolgreich in die Zwischenablage kopiert'));
        }).catch(err => {
            console.error('Link konnte nicht kopiert werden: ', err);
            setErrorMessage(t('Link konnte nicht kopiert werden: '));

        });

        directus.items('anmeldungen').updateOne(id, {
            personal_message: personalMessage,
            todos_requested: 'true',
        }).then((response) => {

            console.log('yes')
        }).catch((e) => {
            setErrorMessage(t('Upps, etwas ist schief gelaufen.'));
        })


    }


    return (
        <div>
            {data && (
                <div className={'anmeldung-preview'}>

                    <div className="overview-wrapper columns-2">
                        <div className="overview-item">
                            <div className="abschnitt">
                                <h2>{t('Schule')}</h2>
                                <div>{data.schule || ""}</div>
                                <div>{data.adresse || ""}</div>
                                <div>{`${data.plz || ""} ${data.ort || ""}`}</div>
                            </div>
                            <div className="abschnitt">
                                <h2>{t('Kontakt')}</h2>
                                <div>{`${data.vorname || ""} ${data.nachname || ""}`}</div>
                                <div><a href={`mailto:${data.email || ""}`}>{data.email || ""}</a></div>
                                <div>{data.telefon || ""}</div>
                            </div>
                            <div className="abschnitt">
                                <h2>{t('Zufahrt')}</h2>
                                <div>{data.platz_zufahrt || ""}</div>
                            </div>
                        </div>
                        <div className="overview-item">
                            {data &&
                                <Standort data={data} />
                            }
                        </div>
                    </div>


                    <div className="overview-wrapper">

                        {data?.slots &&
                            <Slots slotIds={data.slots} />
                        }

                        <h3>{t('Alternative Termine')}</h3>
                        <div>{data['alternative_termine'] || t("keine_alternative_termine")}</div>

                        <EditSlotsModal id={id} reloadData={loadData} />

                    </div>

                    <WorkshopDetails id={id} hub={data.hub.id} reloadData={loadData} />


                    <LeiterpersonenZuteilung anmeldung={data} />


                    <AnmeldungBestaetigen
                        status={data.status}
                        confirmAnmeldung={confirmAnmeldung}
                        setPersonalMessage={setPersonalMessage}
                        personalMessage={personalMessage}
                    />

                    <div className="overview-wrapper">
                        <NextSteps>

                            <ToDoItem state={data.status === 'confirmed'} text={t('termine_erfolgreich')} />
                            <ToDoItem state={data.platz !== null} text={t('platz_erfasst')} />
                            <ToDoItem state={data.anhaenger_standort !== null} text={t('anhaenger_erfasst')} />



                            {/* 

                            <DetailsAnfragen
                                data={data}

                            />*/}
                        </NextSteps>

                    </div>

                    <div className="overview-wrapper">
                        <h3>{t('Slot Workshop Zuteilung')}</h3>
                        <div>{t("slot_workshop_zuteilung_text")}</div>
                        <button className="button small button--inline" onClick={() => copyLinkTopClipboard(data.uuid)}>{t('Link kopieren')}</button>
                    </div>


                    <Reports data={data} />


                    <HistoryItems data={data} />

                </div>
            )
            }
        </div>
    );
}

AnmeldungPreview.defaultProps = {
    isEditPage: false,
};

AnmeldungPreview.propTypes = {
    isEditPage: PropTypes.bool,
};

export default AnmeldungPreview;