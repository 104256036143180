import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { DirectusProvider } from 'react-directus';
import { ErrorProvider } from './context/ErrorContext';
import { DirectusOptions } from '@directus/sdk';

const root = ReactDOM.createRoot(document.getElementById('root'));
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const headers = {

}

const transport = {
  headers: headers
}

const options = {
  transport: transport,
  autoLogin: true,
  withCredentials: true,
  mode: 'cookie',
  autoRefresh: true,
  msRefreshBeforeExpires: 30000,
  includeCredentialsOnRefresh: false,
  includeCredentials: false,
}

const config = {
  auth: {
    mode: 'json', // 'json' in Node.js
    autoRefresh: true,
    msRefreshBeforeExpires: 30000,
    staticToken: '',
  },
  storage: {
    prefix: '',
    mode: 'LocalStorage', // 'MemoryStorage' in Node.js
  },
  transport: {
    params: {},
    headers: {},
    onUploadProgress: (ProgressEvent) => { },
    maxBodyLength: null,
    maxContentLength: null,
  },
};



root.render(
  <ErrorProvider>

    <DirectusProvider apiUrl={API_ENDPOINT} options={config} autoLogin={true}>
      <BrowserRouter>
        <App />
        {/* 
        <div className="testumgebung">
          <div>
            Testumgebung: Änderungen haben keine Effekt, dies ist eine Testumgebung.
          </div>
        </div>*/}
      </BrowserRouter>

    </DirectusProvider>
  </ErrorProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
