import React, { useEffect, useState } from 'react'
import { formatDate, capitalize } from '@helpers';
import PropTypes from 'prop-types'

export default function WorkshopItem(props) {
    const { workshop, slot } = props;

    console.log(slot)

    return (
        <div className="kalender__workshop-item">
            <div>
                <div className="date">{formatDate(workshop.slot.date)}</div>
                <div className="zeit"> Start: {workshop.startZeit.slice(0, 5)}</div>
            </div>
        </div>
    );

}

WorkshopItem.propTypes = {

}