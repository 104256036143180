import './leiter-signup.scss';
import SignupKalender from './components/SignupKalender';
import React, { useState } from 'react';
import MyWorkshops from './components/MyWorkshops';

const LeiterSignup = () => {
    const queryString = window.location.search;
    const [myWorkshops, setMyWorkshops] = useState(false);

    // Create a URLSearchParams object to parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Access the 'uuid' parameter using the get method
    const uuid = urlParams.get('uuid');
    const id = urlParams.get('id');
    return <div className="leiter-signup-page">
        <div className="menu">
            <div className={`menu--item ${!myWorkshops ? 'active' : null}`} onClick={() => setMyWorkshops(false)}>Verfügbarkeiten</div>
            <div className={`menu--item ${myWorkshops ? 'active' : null}`} onClick={() => setMyWorkshops(true)}>Meine Workshops</div>
        </div>

        <div>
            {myWorkshops ? <MyWorkshops uuid={uuid} id={id} /> :
                <SignupKalender uuid={uuid} id={id} />

            }
        </div>
    </div >;
};



export default LeiterSignup;