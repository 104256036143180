import React, { useEffect, useState } from 'react'
import './kalender.scss'

import { useDirectus } from 'react-directus';
import { getDataOnLoad } from '@helpers';
import AnmeldungItem from './AnmeldungItem';
const collection = 'anmeldungen';
const fields = ['*', 'slots.slots_id'];

export default function Kalender() {

    const { directus } = useDirectus();
    const [anmeldungen, setAnmeldungen] = useState([]);

    const customSort = (a, b) => {
        if (!a.tmpSlots || !b.tmpSlots || a.tmpSlots.length === 0 || b.tmpSlots.length === 0) {
            return 0;
        }

        if (a.tmpSlots[0].date < b.tmpSlots[0].date) {
            return -1;
        }
        if (a.tmpSlots[0].date > b.tmpSlots[0].date) {
            return 1;
        }
        return 0;
    }


    const fetchSlots = async (slotIds) => {
        const filteredSlotIds = slotIds.filter(item => item.slots_id).map(item => item.slots_id);

        if (filteredSlotIds.length === 0) return;

        try {
            const response = await directus.items('slots').readByQuery({
                filter: { id: { _in: filteredSlotIds } },
                fields: ['*']
            });
            return response.data;
        } catch (e) {
            console.error('Error fetching slots:', e);
            // Handle error appropriately
        }
    }

    useEffect(() => {

        const processAnmeldungen = async () => {


            try {
                const response = await getDataOnLoad(collection, fields, directus);
                const tmpData = await Promise.all(response.data.map(async (anmeldung) => {

                    return {
                        id: anmeldung.id,
                        ...JSON.parse(anmeldung.fields),
                        ...anmeldung,
                        tmpSlots: await fetchSlots(anmeldung.slots)
                    };
                }));

                tmpData.sort(customSort);
                setAnmeldungen(tmpData);


            } catch (e) {
                console.log(e)
            }
        }


        processAnmeldungen();
    }, []);

    return (
        <div className="kalender">
            {anmeldungen.map((anmeldung) => (
                <AnmeldungItem anmeldung={anmeldung} key={anmeldung.id} />)
            )}

        </div>
    )
}
